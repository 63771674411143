<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfig"
      :pageTitle="pageTitle"
      :pageInfo="pageInfo"
      pageType="view"
    >
    </page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/message.modal.js'
import { useStore } from 'vuex'
import { ref } from 'vue'
import i18n from '@/i18n'
import { useRoute } from 'vue-router'

// 页面标题
const pageTitle = ref('')
const { t } = i18n.global
const route = useRoute()
const id = route.params.id
const pageInfo = ref({})
pageTitle.value = t('general.router-message-read')

const store = useStore()

const getCurrentPageInfo = async () => {
  // 获取当前页面的初始化值
  const messageInfo = await store.dispatch('system/getReceiveMessageById', id)
  await store.dispatch('system/handleReadMessage', { id })
  pageInfo.value = messageInfo
}

getCurrentPageInfo()
</script>
