import { generateColLayout } from '@/utils/util'
// import { getGlobalParamsOptionsAsync } from '@/utils/common.js'

export const baseFormConfig = {
  formItems: [
    {
      field: 'createdBy',
      type: 'input',
      label: 'profile.sender',
      // options: [],
      // isResolveGlobalParams: true,
      // handler: () =>
      //   getGlobalParamsOptionsAsync('userList', {
      //     isNeedPreFix: false,
      //     valueParams: 'userName',
      //     labelParams2: 'userName'
      //   }),
      otherOptions: {
        // multiple: true,
        disabled: true
      },
      colLayout: generateColLayout(12)
    },
    {
      field: 'receiver',
      type: 'input',
      label: 'profile.receiver',
      colLayout: generateColLayout(12),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'title',
      type: 'input',
      label: 'profile.title',
      colLayout: generateColLayout(24),
      otherOptions: {
        maxlength: 80,
        disabled: true
      }
    },
    {
      field: 'content',
      type: 'editor',
      label: 'profile.message',
      colLayout: generateColLayout(24),
      otherOptions: {
        maxlength: 500,
        disabled: true,
        isShowToolBar: false
      }
    }
  ]
}
